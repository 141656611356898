










import { Vue, Component } from 'vue-property-decorator';
import { PokerSession, User } from '../models';
import { defaultPokerSession } from '../defaults';
import { pokerService } from '../services';
import SessionBuilder from '../components/SessionBuilder.vue';

@Component({ components: { SessionBuilder } })
export default class CreateSession extends Vue {
  session: PokerSession = defaultPokerSession();

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  mounted() {
    this.$bus.$emit('title-change', 'Poker', '/poker');
    document.title = 'AgileMate New Poker Session';
  }

  async saveSession(session: PokerSession) {
    try {
      session.createdOn = Date.now();
      session.owner = this.userId;
      await pokerService.createSession(session);
      this.$bus.$emit('snackbar-notify', 'Session successfully saved', 'success');
      this.$analytics.logEvent('poker-create', { session_id: session.id, user_id: this.userId });
      this.$router.push('/poker');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
