import { db, firestore } from '@/firebase';
import { getId } from '@/utils';
import { PokerEvent, PokerParticipant, PokerParticipants, PokerRound, PokerSession, User } from './models';

export const pokerService = {
  // References
  getSessionsRef() {
    return db.collection('poker');
  },
  getSessionRef(sessionId: string) {
    return this.getSessionsRef().doc(sessionId);
  },
  getConfigRef() {
    return db.collection('admin').doc('poker');
  },
  // Session
  getAllSessions() {
    return this.getSessionsRef();
  },
  getOwnSessions(userId: string) {
    return this.getSessionsRef().where('owner', '==', userId);
  },
  getDraftSessions(userId: string) {
    return this.getOwnSessions(userId).where('status', '==', 'Draft');
  },
  getActiveSessions(userId: string) {
    return this.getOwnSessions(userId).where('status', '==', 'Active');
  },
  getClosedSessions(userId: string) {
    return this.getOwnSessions(userId).where('status', '==', 'Closed');
  },
  async createSession(session: PokerSession) {
    await this.getSessionRef(session.id).set(session, { merge: true });
  },
  async updateSession(sessionId: string, sessionData: firestore.UpdateData) {
    await this.getSessionRef(sessionId).update(sessionData);
  },
  async deleteSession(sessionId: string) {
    await this.getSessionRef(sessionId).delete();
  },
  async cloneSession(session: PokerSession, user: User): Promise<PokerSession> {
    const newSession: PokerSession = { ...session };
    newSession.id = getId();
    newSession.status = 'Draft';
    newSession.storyIndex = -1;
    newSession.stories = [];
    newSession.round = null;
    newSession.rounds = [];
    newSession.people = {} as PokerParticipants;
    newSession.events = [];
    newSession.owner = user.id;
    newSession.createdOn = Date.now();
    newSession.updatedOn = null;
    newSession.startedOn = null;
    newSession.completedOn = null;
    await this.createSession(newSession);
    return newSession;
  },
  // Rounds
  async createRound(sessionId: string, round: PokerRound) {
    await this.updateSession(sessionId, { ['rounds']: firestore.FieldValue.arrayUnion(round) });
  },
  async deleteRound(sessionId: string, round: PokerRound) {
    await this.updateSession(sessionId, { ['rounds']: firestore.FieldValue.arrayRemove(round) });
  },
  // Participants
  async createParticipant(sessionId: string, participant: PokerParticipant) {
    await this.updateSession(sessionId, { [`people.${participant.id}`]: participant });
  },
  async updateParticipant(sessionId: string, participant: PokerParticipant) {
    await this.updateSession(sessionId, { [`people.${participant.id}`]: participant });
  },
  async deleteParticipant(sessionId: string, participantId: string) {
    await this.updateSession(sessionId, { [`people.${participantId}`]: firestore.FieldValue.delete() });
  },
  // Events
  async createEvent(sessionId: string, event: PokerEvent) {
    await this.updateSession(sessionId, { ['events']: firestore.FieldValue.arrayUnion(event) });
  },
};

export default { pokerService };
