























import { Vue, Component, Prop } from 'vue-property-decorator';
import { formatDate } from '@/utils';
import { PokerSession, PokerEvents, PokerTheme } from '../models';

@Component
export default class Log extends Vue {
  @Prop({ type: Object as () => PokerSession, required: true })
  readonly session!: PokerSession;

  @Prop({ type: Object as () => PokerTheme, required: true })
  readonly theme!: PokerTheme;

  formatDate = formatDate;
  headers = [
    { text: 'Timestamp', value: 'timestamp', width: '10%' },
    { text: 'Event', value: 'title' },
  ];

  get events(): PokerEvents {
    return this.session.events;
  }
}
