import { getId, getPexelPhoto } from '@/utils';
import { PokerSession, PokerParticipants, PokerThemes } from './models';

export const defaultThemes: PokerThemes = {
  Cards: {
    id: 'Cards',
    title: 'Cards',
    description: 'Cards theme',
    background: null,
    image: getPexelPhoto(1479465),
    colors: { text: 'white', highlight: 'brown lighten-3 black--text', background: '#000000CC' },
  },
  Clean: {
    id: 'Clean',
    title: 'Clean',
    description: 'Clean theme (default)',
    background: '#E8EAF6',
    colors: { text: 'black', highlight: 'blue white--text', background: '#FFFFFF' },
  },
  CleanDark: {
    id: 'CleanDark',
    title: 'Clean Dark',
    description: 'Clean dark theme',
    background: '#616161',
    colors: { text: 'white', highlight: 'amber black--text', background: '#1E1E1E' },
  },
};

export const defaultPokerSession = (): PokerSession => ({
  id: getId(),
  title: 'Poker Session',
  status: 'Draft',
  theme: 'Cards',
  cards: { text: 'Scrum', value: ['0', '½', '1', '2', '3', '5', '8', '13', '20', '40', '100', '?', '☕'] },
  people: {} as PokerParticipants,
  storyIndex: null,
  stories: [],
  round: null,
  rounds: [],
  events: [],
  ownerCanEstimate: false,
  autoStartRound: true,
  autoShowCards: true,
  autoCompleteLevel: 0,
});
