










































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { firestore } from '@/firebase';
import { downloadJson, formatDate, getStatusColor } from '@/utils';
import { defaultThemes } from '../defaults';
import {
  PokerSession,
  PokerParticipant,
  PokerStory,
  PokerEvent,
  PokerRound,
  PokerTheme,
  PokerParticipants,
  PokerEstimates,
  User,
} from '../models';
import { pokerService } from '../services';
import Participants from '@/components/Participants.vue';
import Cards from '../components/Cards.vue';
import Story from '../components/Story.vue';
import Estimate from '../components/Estimate.vue';
import Results from '../components/Results.vue';
import Log from '../components/Log.vue';

Component.registerHooks(['beforeRouteLeave']);

@Component({ components: { Participants, Cards, Story, Estimate, Results, Log } })
export default class ViewSession extends Vue {
  async beforeRouteLeave(to, from, next) {
    await this.disconnect();
    next();
  }

  @Prop({ type: String, required: true })
  readonly id!: string;

  session: PokerSession | null = null;
  story: PokerStory | null = null;
  formatDate = formatDate;
  getStatusColor = getStatusColor;

  get theme(): PokerTheme {
    const themeName = this.session && this.session['theme'] ? this.session.theme : 'Cards';
    return defaultThemes[themeName];
  }
  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }
  get participant(): PokerParticipant {
    const participant: PokerParticipant = this.user;
    participant.owner = this.session ? this.session.owner : null;
    return participant;
  }
  get participants(): PokerParticipant[] {
    const participants = this.session && this.session.people ? Object.values(this.session.people) : [];
    return participants.sort((a, b) => a.username!.localeCompare(b.username!));
  }
  get isOwner(): boolean {
    return this.session ? this.userId === this.session.owner : false;
  }
  get isReadOnly(): boolean {
    return this.session ? this.session.status === 'Closed' : false;
  }
  get isEstimationReady(): boolean {
    if (!this.session) return false;
    let status = false;
    if (this.session.round && this.session.round.estimates) {
      const estimatesCount = Object.values(this.session.round.estimates).length;
      const peopleCount = this.participants.length;
      status = peopleCount === estimatesCount;
    }
    return status;
  }

  @Watch('id', { immediate: true })
  async onIdChange(id: string) {
    this.$bus.$emit('loading-indicator', true);
    await this.$bind('session', pokerService.getSessionRef(id));
    //await this.$bind('participants', pokerService.getParticipantsRef(id).orderBy('username'));
    await this.connectUser(this.user);
    this.$bus.$emit('loading-indicator', false);
    if (this.session) {
      document.title = this.session.title;
      this.$analytics.logEvent('poker-view', { session_id: this.id, user_id: this.userId });
    }
  }

  created() {
    //window.addEventListener('beforeunload', this.onBeforeUnload);
    window.onbeforeunload = this.onBeforeUnload;

    // Event listeners
    this.$bus.$off('estimation-start').$on('estimation-start', this.onEstimationStart);
    this.$bus.$off('estimation-restart').$on('estimation-restart', this.onEstimationRestart);
    this.$bus.$off('estimation-finish').$on('estimation-finish', this.onEstimationFinish);
    this.$bus.$off('estimation-change').$on('estimation-change', this.onEstimationChange);
    this.$bus.$off('estimation-remove').$on('estimation-remove', this.onEstimationRemove);
    this.$bus.$off('person-remove').$on('person-remove', this.onPersonRemove);
    //this.$bus.$off('session-save').$on('session-save', this.saveSession);
  }

  mounted() {
    this.$bus.$emit('title-change', 'Poker', '/poker');
    this.$bus.$emit('loading-indicator', true);
  }

  onBeforeUnload() {
    this.disconnect();
  }

  async connectUser(user: PokerParticipant) {
    if (this.isReadOnly) return;
    const person: PokerParticipant = this.participant;
    person.lastJoinedOn = Date.now();
    await pokerService.createParticipant(this.id, person);
  }
  async disconnectUser(user: PokerParticipant) {
    if (this.isReadOnly) return;
    await pokerService.deleteParticipant(this.id, this.user.id);
  }
  async disconnect() {
    await this.disconnectUser(this.user);
  }

  async setSessionStatus(status: string) {
    if (!this.session) return;
    this.session.status = status;
    if (status === 'Active') {
      this.session.completedOn = null;
      this.session.startedOn = Date.now();
    } else if (status === 'Closed') {
      this.session.completedOn = Date.now();
    }
    // TODO: Check if the above code is needed, or we can just pass the changes to the service and reactively propagate them
    await pokerService.updateSession(this.session.id, {
      status: this.session.status,
      startedOn: this.session.startedOn,
      completedOn: this.session.completedOn,
    });
  }

  exportSession() {
    if (!this.session) return;
    downloadJson(this.session, this.session.id);
    this.$bus.$emit('snackbar-notify', 'Session successfully exported', 'success');
  }

  // Stories
  async saveStory(story: PokerStory) {
    if (!this.session || !this.session.round) return;
    await pokerService.updateSession(this.session.id, { ['round.story']: story });
    this.$bus.$emit('snackbar-notify', 'Story successfully saved', 'success');
  }

  // Event handlers
  async onEstimationStart(people: PokerParticipants) {
    if (!this.session) return;
    const roundNumber = this.session.rounds.length + 1;
    const storyCount = this.session.stories.length;
    const round: PokerRound = {
      number: roundNumber,
      status: 'Started',
      people: people,
      story: null,
      estimates: {} as PokerEstimates,
      points: null,
      confidence: null,
      startedOn: Date.now(),
      completedOn: null,
    };
    /*
    let storyIndex = this.session.storyIndex;
    if (this.session.storyIndex === null && storyCount > 0) {
      storyIndex = -1;
    }
    if (storyIndex !== null && storyCount > 0 && storyIndex < storyCount - 1) {
      storyIndex++;
      round.story = this.session.stories[storyIndex];
    } else {
      storyIndex = null;
      round.story = null;
    }
    */
    const event: PokerEvent = {
      title: `Estimation round ${round.number} started`,
      timestamp: Date.now(),
      uid: this.user.id,
      color: 'info',
    };
    this.$bus.$emit('snackbar-notify', event.title, event.color);
    await pokerService.updateSession(this.session.id, {
      round: round,
      events: firestore.FieldValue.arrayUnion(event),
      //storyIndex: storyIndex,
    });
  }
  async onEstimationRestart(round: PokerRound, people: PokerParticipants) {
    if (!this.session) return;
    round.status = 'Started';
    round.people = people;
    round.estimates = {} as PokerEstimates;
    round.points = null;
    round.confidence = null;
    round.startedOn = Date.now();
    round.completedOn = null;
    const event: PokerEvent = {
      title: `Estimation round ${round.number} restarted`,
      timestamp: Date.now(),
      uid: this.user.id,
      color: 'info',
    };
    this.$bus.$emit('snackbar-notify', event.title, event.color);
    await pokerService.updateSession(this.session.id, {
      round: round,
      events: firestore.FieldValue.arrayUnion(event),
    });
  }
  async onEstimationFinish(round: PokerRound, people: PokerParticipants) {
    if (!this.session) return;
    round.people = people;
    round.completedOn = Date.now();
    round.status = 'Completed';
    const event: PokerEvent = {
      title: `Estimation round ${round.number} completed`,
      timestamp: Date.now(),
      uid: this.user.id,
      color: 'success',
    };
    this.session.events.push(event);
    this.$bus.$emit('snackbar-notify', event.title, event.color);
    await pokerService.updateSession(this.session.id, {
      round: null,
      rounds: firestore.FieldValue.arrayUnion(round),
      events: firestore.FieldValue.arrayUnion(event),
    });
    if (this.session.autoStartRound) await this.onEstimationStart(people);
  }
  async onEstimationChange(round: PokerRound, estimate: string, user: User) {
    if (!this.session) return;
    const event: PokerEvent = {
      title: `Estimate ${estimate} submitted by ${user.username}`,
      timestamp: Date.now(),
      uid: this.user.id,
      color: 'default',
    };
    this.$bus.$emit('snackbar-notify', event.title, event.color);
    await pokerService.updateSession(this.session.id, {
      [`round.estimates.${user.id}`]: estimate,
      events: firestore.FieldValue.arrayUnion(event),
    });
  }
  async onEstimationRemove(round: PokerRound, estimate: string, user: User) {
    if (!this.session) return;
    const event: PokerEvent = {
      title: `Estimate ${estimate} removed by ${user.username}`,
      timestamp: Date.now(),
      uid: this.user.id,
      color: 'error',
    };
    this.$bus.$emit('snackbar-notify', event.title, event.color);
    await pokerService.updateSession(this.session.id, {
      [`round.estimates.${user.id}`]: firestore.FieldValue.delete(),
      events: firestore.FieldValue.arrayUnion(event),
    });
  }
  async onPersonRemove(round: PokerRound, person: PokerParticipant) {
    if (!this.session || !this.session.round || !this.session.round.people) return;
    delete this.session.round.people[person.id];
    const event: PokerEvent = {
      title: `Participant ${person.username} removed`,
      timestamp: Date.now(),
      uid: this.user.id,
      color: 'error',
    };
    this.$bus.$emit('snackbar-notify', event.title, event.color);
    await pokerService.updateSession(this.session.id, {
      [`round.people.${person.id}`]: firestore.FieldValue.delete(),
      events: firestore.FieldValue.arrayUnion(event),
    });
    await pokerService.deleteParticipant(this.session.id, person.id);
  }
}
