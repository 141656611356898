





































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { formatDate, formatInterval, formatPercent, getStatusColor } from '@/utils';
import { PokerSession, PokerTheme } from '../models';

@Component
export default class Results extends Vue {
  @Prop({ type: Object as () => PokerSession, required: true })
  readonly session!: PokerSession;

  @Prop({ type: Object as () => PokerTheme, required: true })
  readonly theme!: PokerTheme;

  formatDate = formatDate;
  formatInterval = formatInterval;
  formatPercent = formatPercent;
  getStatusColor = getStatusColor;

  get isOwner(): boolean {
    return this.session.owner === this.$store.getters['user/userId'];
  }

  getConfidenceColor(confidence: number) {
    let color = 'default';
    if (confidence >= 0.8) color = 'success';
    else if (confidence >= 0.5) color = 'warning';
    else if (confidence >= 0) color = 'error';
    return color;
  }
}
