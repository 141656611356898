














import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { PokerSession } from '../models';
import { pokerService } from '../services';
import SessionBuilder from '../components/SessionBuilder.vue';

@Component({ components: { SessionBuilder } })
export default class EditSession extends Vue {
  @Prop({ type: String, required: true })
  readonly id!: string;

  session: PokerSession | null = null;
  isOwner: boolean | null = null;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  @Watch('id', { immediate: true })
  async onIdChange(id: string) {
    this.$bus.$emit('loading-indicator', true);
    await this.$bind('session', pokerService.getSessionRef(id));
  }

  @Watch('session')
  onSessionChange(session: PokerSession) {
    if (session) {
      document.title = session.title;
      this.isOwner = session.owner === this.userId;
      this.$bus.$emit('loading-indicator', false);
      this.$analytics.logEvent('poker-edit', { session_id: this.id, user_id: this.userId });
    }
  }

  mounted() {
    this.$bus.$emit('title-change', 'Poker', '/poker');
    this.$bus.$emit('loading-indicator', true);
  }

  async saveSession(session: PokerSession) {
    try {
      session.updatedOn = Date.now();
      await pokerService.updateSession(session.id, session);
      this.$bus.$emit('snackbar-notify', 'Session successfully saved', 'success');
      this.$analytics.logEvent('poker-save', { session_id: session.id, user_id: this.userId });
      //this.$router.push(`/poker/${this.session.id}`);
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }

  async deleteSession(session: PokerSession) {
    try {
      await pokerService.deleteSession(session.id);
      this.$bus.$emit('snackbar-notify', 'Session successfully deleted', 'success');
      this.$analytics.logEvent('poker-delete', { session_id: session.id, user_id: this.userId });
      this.$router.push('/poker');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
