





























import { Vue, Component, Prop } from 'vue-property-decorator';
import { PokerSessions } from '../models';
import SessionTile from './SessionTile.vue';
import SessionRow from './SessionRow.vue';

@Component({ components: { SessionTile, SessionRow } })
export default class SessionsList extends Vue {
  @Prop({ type: Array as () => PokerSessions, required: true })
  readonly sessions!: PokerSessions;

  @Prop({ type: Boolean, default: false })
  readonly tiles!: boolean;
}
