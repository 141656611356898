

























import { Vue, Component, Prop } from 'vue-property-decorator';
import { formatDate } from '@/utils';
import { PokerSession, PokerParticipant } from '../models';
import SessionActions from './SessionActions.vue';

@Component({ components: { SessionActions } })
export default class SessionTile extends Vue {
  @Prop({ type: Object as () => PokerSession, required: true })
  readonly value!: PokerSession;

  formatDate = formatDate;

  get participants(): PokerParticipant[] {
    return this.value && this.value.people ? Object.values(this.value.people) : [];
  }
}
