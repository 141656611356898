














import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Card extends Vue {
  @Prop({ type: String, default: null })
  readonly value!: string;

  @Prop({ type: String, default: '45' })
  readonly width!: string;

  @Prop({ type: String, default: '60' })
  readonly height!: string;

  @Prop({ type: String, default: 'secondary' })
  readonly color!: string;

  selectCard() {
    this.$emit('card-selected', this.value);
  }
}
