








































import { Vue, Component, Prop } from 'vue-property-decorator';
import { PokerSession, PokerTheme } from '../models';

const defaultTheme = { colors: { text: 'default', background: '#00000000' } };

@Component
export default class SessionsActions extends Vue {
  @Prop({ type: Object as () => PokerSession, required: true })
  readonly value!: PokerSession;

  @Prop({ type: Object as () => PokerTheme, default: () => defaultTheme })
  readonly theme!: PokerTheme;
}
