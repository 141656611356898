
















import { Vue, Component, Prop } from 'vue-property-decorator';
import { PokerSession, PokerDeck, PokerTheme } from '../models';
import Card from './Card.vue';

@Component({ components: { Card } })
export default class Cards extends Vue {
  @Prop({ type: Object as () => PokerSession, required: true })
  readonly session!: PokerSession;

  @Prop({ type: Object as () => PokerTheme, required: true })
  readonly theme!: PokerTheme;

  cardDialog = false;
  cardValue = null;
  selectedCard = null;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get cards(): PokerDeck {
    return this.session.cards;
  }
  get canEstimate(): boolean {
    return (
      this.userId !== this.session.owner ||
      (this.userId === this.session.owner && this.session.ownerCanEstimate === true)
    );
  }

  cardZoomed(value) {
    this.cardValue = value;
    this.cardDialog = true;
  }
  cardSelected(value) {
    this.cardValue = value;
    this.cardDialog = false;
    this.selectedCard = value;
    this.$emit('card-select', value);
    this.$bus.$emit('card-select', value);
  }
}
