




























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { PokerSession, PokerStory, PokerTheme } from '../models';

@Component
export default class Story extends Vue {
  @Prop({ type: Object as () => PokerSession, required: true })
  readonly session!: PokerSession;

  @Prop({ type: Object as () => PokerTheme, required: true })
  readonly theme!: PokerTheme;

  story: PokerStory = { title: null };

  @Watch('session.round', { immediate: true })
  onRoundChange(round) {
    if (round) {
      this.story = round.story || { title: '' };
    }
  }

  saveStory() {
    this.$emit('save', this.story);
  }
  deleteStory() {
    this.story = { title: null };
    this.$emit('save', null);
  }
}
