













































import { Vue, Component, Prop } from 'vue-property-decorator';
import { PokerSessions } from '../models';
import { pokerService } from '../services';
import SessionsList from './SessionsList.vue';

@Component({ components: { SessionsList } })
export default class Sessions extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly tiles!: boolean;

  draftSessions: PokerSessions = [];
  activeSessions: PokerSessions = [];
  closedSessions: PokerSessions = [];

  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  created() {
    this.$bind('draftSessions', pokerService.getDraftSessions(this.userId).orderBy('createdOn', 'desc'));
    this.$bind('activeSessions', pokerService.getActiveSessions(this.userId).orderBy('createdOn', 'desc'));
    this.$bind('closedSessions', pokerService.getClosedSessions(this.userId).orderBy('createdOn', 'desc'));
  }
}
