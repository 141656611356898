



























import { Vue, Component } from 'vue-property-decorator';
import { downloadJson } from '@/utils';
import { PokerSession, User } from '../models';
import { pokerService } from '../services';
import Sessions from '../components/Sessions.vue';

@Component({ components: { Sessions } })
export default class Dashboard extends Vue {
  get user(): User {
    return this.$store.getters['user/user'];
  }
  get tiles(): boolean {
    return this.$store.getters['poker/tiles'];
  }
  get viewIcon(): string {
    return this.tiles ? 'mdi-view-list' : 'mdi-view-grid';
  }

  created() {
    // Event listeners
    this.$bus.$off('session-clone').$on('session-clone', this.onSessionClone);
    this.$bus.$off('session-export').$on('session-export', this.onSessionExport);
    this.$bus.$off('session-delete').$on('session-delete', this.onSessionDelete);
  }

  mounted() {
    this.$bus.$emit('title-change', 'Poker', '/poker');
    document.title = 'AgileMate Poker';
    //this.$analytics.setCurrentScreen(document.title);
    this.$analytics.logEvent('poker-dashboard');
  }

  toggleView() {
    this.$store.dispatch('poker/toggleTiles');
  }

  // Event handlers
  async onSessionClone(session: PokerSession) {
    try {
      await pokerService.cloneSession(session, this.user);
      this.$bus.$emit('snackbar-notify', 'Session successfully cloned', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onSessionExport(session: PokerSession) {
    try {
      downloadJson(session, session.id);
      this.$bus.$emit('snackbar-notify', 'Session successfully exported', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onSessionDelete(session: PokerSession) {
    try {
      await pokerService.deleteSession(session.id);
      this.$bus.$emit('snackbar-notify', 'Session successfully deleted', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
